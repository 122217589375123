
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class AnsweredQuestion extends Vue {

    @Prop({
        default: () => new OM.AnsweredQuestion()
    }) answer: OM.AnsweredQuestion;

    created() {
    }

}
