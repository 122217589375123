
import { Options, Vue } from 'vue-class-component';
import { AppUserClient, SurveyConfigClient } from '@/services/Services';
import AnsweredQuestion from '../components/answeredQuestion.vue';
import VideoModal from '../modals/videoModal.vue';
import * as OM from '@/Model';

@Options({
    components: {
        AnsweredQuestion
    }
})
export default class UtenteDetail extends Vue {

    userDetail: OM.ProfileEditVM = new OM.ProfileEditVM();
    surveyTotalQuestions: number = 0;

    created(){
        this.init();
    }

    init() {
        Promise.all([
            AppUserClient.getProfileDetail(this.$route.params.userIdentifier as string),
            SurveyConfigClient.getTotalQuestionsForUser(this.$route.params.userIdentifier as string)
        ])
        .then(xs => {
            this.userDetail = xs[0];
            this.surveyTotalQuestions = xs[1];
        })
    }

    openVideoModal() {
        this.$opModal.show(VideoModal, {
            userCompleteName: this.userDetail.personalData.name + " " + this.userDetail.personalData.surname,
            videoUrl: this.userDetail.profileVideo.publicUrl,
            removeVideo: (reason: string) => {
                var model = new OM.TextIdentifier();
                model.identifier = this.userDetail.identifier;
                model.text = reason;

                AppUserClient.removeVideoFromUser(model)
                .then(x => {
                    this.$opModal.closeLast();
                    this.init();
                })
            }
        })
    }

    addFreeMatch() {
        AppUserClient.addFreeMatchToUser(this.userDetail.identifier)
        .then(x => {
            this.init();
        })
    }

}
